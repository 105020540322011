import { call, put, fork, take } from "redux-saga/effects"

export function* createItem(actionTypes, apiFunc, data) {
  yield put({ type: actionTypes.request })
  try {
    const details = yield call(apiFunc, data)
    yield put({
      type: actionTypes.success,
      payload: {
        details,
        id: details.id,
        receivedAt: Date.now(),
      },
      details,
      id: details.id,
      receivedAt: Date.now(),
    })
  } catch (error) {
    yield put({
      type: actionTypes.failure,
      error: error.message,
      payload: { error: error.message, status: error.statusCode },
    })
  }
}

export function itemCreationFlowFactory(actionTypes, apiFuncFromAction) {
  return function* () {
    while (true) {
      let action = yield take([actionTypes.fetch, actionTypes.reset])
      if (action.type === actionTypes.reset) {
        // start flow again after reset
        continue
      }
      yield fork(
        createItem,
        actionTypes,
        apiFuncFromAction(action),
        action.data || action.payload
      )
      yield take(actionTypes.request)
      action = yield take([actionTypes.success, actionTypes.failure])
    }
  }
}
