import { omit } from "lodash"

const initState = {
  loading: false,
  didInvalidate: false,
}

function itemState(actionTypes, state = initState, action) {
  switch (action.type) {
    case actionTypes["updateRequest"]:
      return {
        ...state,
        loading: true,
      }

    case actionTypes["updateSuccess"]:
      return omit(
        {
          ...state,
          loading: false,
          didInvalidate: true,
        },
        ["error"]
      )

    case actionTypes["updateFailure"]:
      return {
        ...state,
        loading: false,
        didInvalidate: false,
        error: action.error,
      }

    case actionTypes["reset"]:
      return {
        loading: false,
        didInvalidate: false,
      }

    case actionTypes["invalidate"]:
      return {
        ...state,
        didInvalidate: true,
      }

    default:
      return state
  }
}

function updatingReducerFactory(actionTypes) {
  const usedActionTypes = Object.values(actionTypes)
  const supportedActions = [
    "updateRequest",
    "updateSuccess",
    "updateFailure",
    "reset",
    "invalidate",
  ]
  for (let key of supportedActions) {
    actionTypes[key] = actionTypes[key] || ""
  }

  return (state = {}, action) => {
    if (usedActionTypes.includes(action.type)) {
      return {
        ...state,
        [action.id]: itemState(actionTypes, state[action.id], action),
      }
    } else {
      return state
    }
  }
}

export default updatingReducerFactory
